var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("vs-card", [
        _c("h3", { staticClass: "font-semibold mb-2" }, [
          _vm._v(
            _vm._s(
              _vm.$t(_vm.resources.StatusByPeriod.i18n) ||
                _vm.resources.StatusByPeriod.name
            )
          )
        ]),
        _c("h6", { staticClass: "mb-base" }, [
          _vm._v(
            _vm._s(
              _vm.$t(_vm.alerts.MsgStatusByPeriod.i18n) ||
                _vm.alerts.MsgStatusByPeriod.i18n
            ) + "."
          )
        ]),
        _c("div", { staticClass: "vx-row mt-5 ml-1" }, [
          _c(
            "div",
            { staticClass: "vx-col w-full md:w-1/6 mb-base" },
            [
              _c("vs-input", {
                staticClass: "w-full",
                attrs: {
                  "icon-pack": "feather",
                  icon: "icon-user",
                  "icon-no-border": "",
                  label:
                    _vm.$t(_vm.resources.DistributorID.i18n) ||
                    _vm.resources.DistributorID.name
                },
                model: {
                  value: _vm.itaCodeForm,
                  callback: function($$v) {
                    _vm.itaCodeForm = $$v
                  },
                  expression: "itaCodeForm"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "vx-col w-full md:w-1/6 mb-base" },
            [
              _c(
                "vs-select",
                {
                  staticClass: "vx-col w-full mb-2",
                  attrs: {
                    label:
                      _vm.$t(_vm.resources.Period.i18n) ||
                      _vm.resources.Period.name
                  },
                  model: {
                    value: _vm.period,
                    callback: function($$v) {
                      _vm.period = $$v
                    },
                    expression: "period"
                  }
                },
                _vm._l(_vm.periods, function(item, index) {
                  return _c("vs-select-item", {
                    key: index,
                    attrs: {
                      "is-selected": item.isSelected,
                      value: item.value,
                      text: item.text
                    },
                    on: {
                      "update:isSelected": function($event) {
                        return _vm.$set(item, "isSelected", $event)
                      },
                      "update:is-selected": function($event) {
                        return _vm.$set(item, "isSelected", $event)
                      }
                    }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "vx-col w-full md:w-1/6 mb-base mt-5" },
            [
              _c(
                "vs-button",
                {
                  attrs: {
                    color: "primary",
                    type: "filled",
                    disabled: !_vm.validateForm
                  },
                  on: {
                    click: function($event) {
                      return _vm.search()
                    }
                  }
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm.$t(_vm.resources.Search.i18n) ||
                        _vm.resources.Search.name
                    )
                  )
                ]
              )
            ],
            1
          )
        ])
      ]),
      _c("div", { staticClass: "vx-row" }, [
        _c(
          "div",
          { staticClass: "vx-col w-full mb-base" },
          [
            _vm.renderQualification
              ? _c("qualification", {
                  attrs: { "ita-code": _vm.itaCodeForm, period: _vm.period }
                })
              : _vm._e()
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }