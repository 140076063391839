<template>
  <div>
    <vs-card>
      <h3
        class="font-semibold mb-2"
      >{{$t(resources.StatusByPeriod.i18n) || resources.StatusByPeriod.name}}</h3>
      <h6 class="mb-base">{{$t(alerts.MsgStatusByPeriod.i18n) || alerts.MsgStatusByPeriod.i18n}}.</h6>

      <div class="vx-row mt-5 ml-1">
        <div class="vx-col w-full md:w-1/6 mb-base">
          <vs-input
            class="w-full"
            icon-pack="feather"
            icon="icon-user"
            icon-no-border
            :label="$t(resources.DistributorID.i18n) || resources.DistributorID.name"
            v-model="itaCodeForm"
          />
        </div>
        <div class="vx-col w-full md:w-1/6 mb-base">
          <vs-select
            :label="$t(resources.Period.i18n) || resources.Period.name"
            v-model="period"
            class="vx-col w-full mb-2"
          >
            <vs-select-item
              :is-selected.sync="item.isSelected"
              :key="index"
              :value="item.value"
              :text="item.text"
              v-for="(item,index) in periods"
            />
          </vs-select>
        </div>
        <div class="vx-col w-full md:w-1/6 mb-base mt-5">
          <vs-button
            color="primary"
            type="filled"
            :disabled="!validateForm"
            @click="search()"
          >{{$t(resources.Search.i18n) || resources.Search.name}}</vs-button>
        </div>
      </div>
    </vs-card>
    <div class="vx-row">
      <!-- Volumenes -->
      <!-- <div class="vx-col w-full md:w-1/2 mb-base">
        <volumen :ita-code="itaCode" :period="currentPeriod" v-if="renderVolumen"></volumen>
      </div>-->

      <!-- Qualifications -->
      <div class="vx-col w-full mb-base">
        <qualification :ita-code="itaCodeForm" :period="period" v-if="renderQualification"></qualification>
      </div>
    </div>
  </div>
</template>
<script>
import resources from "@/i18n/resources.js";
import alerts from "@/i18n/alerts.js";
import vSelect from "vue-select";
import axios from "axios";
import Qualification from "../general/qualification.vue";
import Volumen from "../general/volumen.vue";

export default {
  data() {
    return {
      resources: resources,
      alerts: alerts,
      period: JSON.parse(localStorage.getItem("periods"))[0],
      periods: [],
      itaCodeForm: JSON.parse(
        localStorage.getItem("userInfo")
      ).itaCode.toString(),
      itaCode: JSON.parse(localStorage.getItem("userInfo")).itaCode,
      renderQualification: true,
      renderVolumen: true
    };
  },
  components: {
    "v-select": vSelect,
    Qualification,
    Volumen
  },
  created: function() {
    this.listPeriodDates();
  },
  computed: {
    validateForm() {
      return !this.errors.any();
    }
  },
  methods: {
    //Get period dates.
    async listPeriodDates() {
      let lastPeriod = JSON.parse(localStorage.getItem("periods"))[0];

      await axios({
        method: "POST",
        url: process.env.VUE_APP_OVAPI + "listPeriodDates",
        data: {
          infotoken: localStorage.getItem("tokenInfo"),
          periodCount: 52,
          lastPeriod: lastPeriod
        },
        headers: {
          "content-type": "application/json",
          Authorization: "bearer " + localStorage.getItem("loggedIn")
        }
      }).then(
        result => {
          var data = result.data.DATA;

          for (var i = 0; i < data.PV_DATE.length; i++) {
            this.periods.push({
              value: data.PV_DATE[i],
              text:
                data.PV_DATE[i].substring(4, 6) +
                "/" +
                data.PV_DATE[i].substring(0, 4)
            });
          }
        },
        error => {
          this.$vs.notify({
            title: "Error",
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    search() {
      this.isInDownline().then(res => {
        if (res == "false") {
          this.isDownline = 0;
          this.$vs.notify({
            title: this.$i18n.t("Alert"),
            text: this.$i18n.t("MsgDistNotExist"),
            color: "warning",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-alert-circle"
          });
        } else {
          this.reloadVolumen();
          this.reloadQualification();
        }
      });
    },

    reloadVolumen() {
      this.renderVolumen = false;

      this.$nextTick(() => {
        // Add the component back in
        this.renderVolumen = true;
      });
    },

    reloadQualification() {
      this.renderQualification = false;

      this.$nextTick(() => {
        // Add the component back in
        this.renderQualification = true;
      });
    },

    async isInDownline() {
      let response = true;
      await axios({
        method: "POST",
        url: process.env.VUE_APP_OVAPI + "isInDownline",
        data: {
          infotoken: localStorage.getItem("tokenInfo"),
          distid: this.itaCode,
          target: this.itaCodeForm
        },
        headers: {
          "content-type": "application/json",
          Authorization: "bearer " + localStorage.getItem("loggedIn")
        }
      }).then(
        result => {
          response = result.data;
        },
        error => {
          this.$vs.notify({
            title: "Error",
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
      return response;
    }
  }
};
</script>
